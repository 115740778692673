import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Container } from "react-bootstrap";

export default function Seguros() {
	const navigate = useNavigate();

	return (
		<Container fluid className="d-flex flex-column bg-primary p-0 shadow" style={{ minHeight: "100dvh", maxWidth: 450 }}>
			<div className="bg-primary text-white p-3">
				<Col className="text-white my-3">
					<div>Seja bem-vindo</div>
					<div className="fs-2">Qual seguro está procurando?</div>
				</Col>
			</div>
			<div className="d-flex flex-column flex-fill align-items-start justify-content-start py-4 bg-body">
				<div className="px-3 mb-3">
					<Card className="rounded-3 overflow-hidden">
						<Card.Img variant="top" src={process.env.PUBLIC_URL + "/card.avif"} style={{ height: 120, objectFit: "cover" }} />
						<Card.Body className="d-flex align-items-center gap-2">
							<div>
								<Card.Title>Casa Protegida</Card.Title>
								<Card.Text>Garanta a proteção da sua casa e concorra a R$ 5.000</Card.Text>
							</div>
							<Button
								className="text-white"
								onClick={() => {
									navigate("/seguro/1");
								}}
							>
								Conhecer
							</Button>
						</Card.Body>
					</Card>
				</div>
				<div className="px-3 mb-3">
					<Card className="rounded-3 overflow-hidden">
						<Card.Img variant="top" src={process.env.PUBLIC_URL + "/card.avif"} style={{ height: 120, objectFit: "cover" }} />
						<Card.Body className="d-flex align-items-center gap-2">
							<div>
								<Card.Title>Você Protegido</Card.Title>
								<Card.Text>Garanta a proteção da sua saúde e concorra a R$ 5.000</Card.Text>
							</div>
							<Button
								className="text-white"
								onClick={() => {
									navigate("/seguro/2");
								}}
							>
								Conhecer
							</Button>
						</Card.Body>
					</Card>
				</div>
			</div>
		</Container>
	);
}
