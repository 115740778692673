import { useNavigate } from "react-router-dom";
import { Button, Container } from "react-bootstrap";

export default function SeguroSolicitado() {
	const navigate = useNavigate();

	return (
		<Container fluid className="d-flex flex-column bg-primary p-0 shadow" style={{ minHeight: "100dvh", maxWidth: 450 }}>
			<div className="bg-primary text-white p-3">
				<div className="fs-2 mb-4">Solicitação</div>
				<div className="fs-2 mb-3 lh-1 text-center">Solicitação concluída.</div>
				<div className="mb-3 text-center">A contratação do seu seguro foi realizada com sucesso.</div>
				<div className="mb-3 text-center">
					Para mais informações, entre em contato pelo telefone <b>0800 545 4555</b> ou pelo e-mail <b>ajuda@ajuda.com</b>.
				</div>
			</div>
			<div className="d-flex flex-column flex-fill py-4 rounded-top-3 bg-white px-3">
				<div className="pb-3 mb-3">
					Você será informado através do e-mail de cadastro assim que o processamento de seu bilhete for concluído e seu seguro estiver disponível para uso.
					<br />
					<br />
					Consulte mais informações sobre as coberturas e assistências do seu seguro clicando aqui.
				</div>
				<Button
					className="rounded-pill"
					variant="outline-primary"
					size="lg"
					onClick={() => {
						navigate("/seguros");
					}}
				>
					Volta a tela inicial
				</Button>
			</div>
		</Container>
	);
}
