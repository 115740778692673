import { useNavigate } from "react-router-dom";
import { Button, Container, FloatingLabel, Form } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiAccountOutline } from "@mdi/js";

export default function Cadastro() {
	const navigate = useNavigate();

	return (
		<Container fluid className="d-flex flex-column bg-primary p-0 shadow" style={{ minHeight: "100dvh", maxWidth: 450 }}>
			<div className="bg-primary text-white p-3">
				<div className="fs-2 mb-5">Cadastro</div>
				<div className="d-flex justify-content-center mb-3">
					<Icon path={mdiAccountOutline} size={4} />
				</div>
				<div className="text-center fs-1 mb-2">Cadastro</div>
				<div className="text-center mb-5">Criar a sua conta.</div>
			</div>
			<div className="d-flex flex-column flex-fill py-4 rounded-top-3 bg-white px-3">
				<div className="mb-4">
					<div className="text-center mb-3">Preencha os campos abaixo</div>
					<FloatingLabel controlId="nome" label="Nome" className="mb-3">
						<Form.Control type="text" placeholder="Informe o nome" />
					</FloatingLabel>
					<FloatingLabel controlId="email" label="E-mail" className="mb-3">
						<Form.Control type="email" placeholder="Informe o e-mail" />
					</FloatingLabel>
					<FloatingLabel controlId="password" label="Senha" className="mb-3">
						<Form.Control type="password" placeholder="12345" />
					</FloatingLabel>
					<FloatingLabel controlId="cep" label="CEP" className="mb-3">
						<Form.Control type="text" placeholder="000-000" />
					</FloatingLabel>
					<div className="text-center mb-3">Endereço</div>
					<FloatingLabel controlId="endereco" label="Endereço" className="mb-3">
						<Form.Control type="text" placeholder="Informe o endereço" />
					</FloatingLabel>
					<FloatingLabel controlId="numero" label="Número" className="mb-3">
						<Form.Control type="number" placeholder="Informe o número" />
					</FloatingLabel>
					<FloatingLabel controlId="complemento" label="Complemento" className="mb-3">
						<Form.Control type="text" placeholder="Informe o complemento" />
					</FloatingLabel>
					<FloatingLabel controlId="bairro" label="Bairro" className="mb-3">
						<Form.Control type="text" placeholder="Informe o bairro" />
					</FloatingLabel>
					<FloatingLabel controlId="estado" label="Estado" className="mb-3">
						<Form.Control type="text" placeholder="Informe o estado" />
					</FloatingLabel>
					<FloatingLabel controlId="cidade" label="Cidade" className="mb-3">
						<Form.Control type="text" placeholder="Informe a cidade" />
					</FloatingLabel>
				</div>

				<div className="d-flex flex-column px-3 gap-3">
					<Button
						className="text-white rounded-pill"
						size="lg"
						onClick={() => {
							navigate("/seguroPagamento");
						}}
					>
						Cadastrar
					</Button>
				</div>
			</div>
		</Container>
	);
}
