import { useNavigate, useParams } from "react-router-dom";
import { Col, Container } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiChevronRight, mdiDrawPen, mdiHomeOutline, mdiShieldAccountOutline, mdiShieldHomeOutline, mdiShieldOutline } from "@mdi/js";

export default function Seguro() {
	const navigate = useNavigate();
	const { id } = useParams();

	return (
		<Container fluid className="d-flex flex-column bg-primary p-0 shadow" style={{ minHeight: "100dvh", maxWidth: 450 }}>
			<div className="bg-primary text-white p-3">
				<div className="fs-2 mb-5">Seguro {id === "1" ? "Residencial" : "Saúde"}</div>
				<div className="d-flex justify-content-center mb-3">
					<Icon path={id === "1" ? mdiShieldHomeOutline : mdiShieldAccountOutline} size={4} />
				</div>
				<div className="text-center fs-1 mb-2 text-center">{id === "1" ? "Seguro Casa Protegida" : "Seguro Você Protegido"}</div>
				<div className="text-center mb-5">
					{id === "1" ? "O Seguro Casa Protegida tem cobertura e assistência para você viver tranquilo." : "O Seguro Você Protegido tem cobertura e assistência para você viver tranquilo."}
				</div>
			</div>
			<div className="d-flex flex-column flex-fill py-4 rounded-top-3 bg-white">
				<Col md={12} className="px-3 pb-3 mb-3 border-bottom">
					<div
						className="d-flex gap-2"
						onClick={() => {
							navigate("/seguroSolicitar/1");
						}}
						style={{ cursor: "pointer" }}
					>
						<div className="d-flex align-items-center">
							<Icon path={mdiHomeOutline} size={1.5} className="text-primary" />
						</div>
						<div className="flex-fill me-2">
							<div className="fs-5">Solicitar seguro</div>
							<div className="">Não tem seguro ainda ou deseja contratar para um novo endereço? Veja as coberturas e também concorra a R$ 5.062,50</div>
						</div>
						<div className="d-flex flex-row align-items-center justify-content-center">
							<Icon path={mdiChevronRight} size={1} className="text-muted" />
						</div>
					</div>
				</Col>
				<Col md={12} className="px-3 pb-3 mb-3 border-bottom">
					<div
						className="d-flex gap-2"
						onClick={() => {
							if (id === "1") {
								window.location.href = "https://bnpparibascardif.com.br/cob-residencial";
							} else {
								window.location.href = "https://bnpparibascardif.com.br/cob-doencas-graves";
							}
						}}
						style={{ cursor: "pointer" }}
					>
						<div className="d-flex align-items-center">
							<Icon path={mdiShieldOutline} size={1.5} className="text-primary" />
						</div>
						<div className="flex-fill me-2">
							<div className="fs-5">Acionar meu seguro</div>
							<div className="">Aconteceu alguma surpresa? Não se preocupe, acione agora seu seguro</div>
						</div>
						<div className="d-flex flex-row align-items-center justify-content-center">
							<Icon path={mdiChevronRight} size={1} className="text-muted" />
						</div>
					</div>
				</Col>
				<Col md={12} className="px-3 pb-3 mb-3 border-bottom">
					<div
						className="d-flex gap-2"
						onClick={() => {
							navigate(`/seguroDetalhes/${id}`);
						}}
						style={{ cursor: "pointer" }}
					>
						<div className="d-flex align-items-center">
							<Icon path={mdiDrawPen} size={1.5} className="text-primary" />
						</div>
						<div className="flex-fill me-2">
							<div className="fs-5">Ver meu seguro</div>
							<div className="">Consulte seu seguro contratado e outras informações que você precisar</div>
						</div>
						<div className="d-flex flex-row align-items-center justify-content-center">
							<Icon path={mdiChevronRight} size={1} className="text-muted" />
						</div>
					</div>
				</Col>
			</div>
		</Container>
	);
}
