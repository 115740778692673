import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginEntity } from "../entities/LoginEntity";
import ApiService from "../services/ApiService";
import { useQueryClient } from "react-query";

import { screens } from "../config/screens";

type AuthContextProps = {
	user: LoginEntity | undefined;
	aplicativo: any;
	routeActive: string;
	handleLogin: (email: string, password: string) => Promise<true | string>;
	handleLogout: () => void;
	handleAplicativo: (id: number) => void;
};

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export function useAuth() {
	return useContext(AuthContext);
}

type AuthProviderProps = {
	children: ReactNode;
};

const locations = screens
	.filter((item) => {
		return item.showSidebar;
	})
	.map((item) => {
		return item.route;
	});

export default function AuthProvider({ children }: AuthProviderProps) {
	const apiService = new ApiService();

	const queryClient = useQueryClient();
	const location = useLocation();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState<LoginEntity | undefined>();
	const [aplicativo, setAplicativo] = useState<number>(1);
	const [routeActive, setRouteActive] = useState<string>("");

	useEffect(() => {
		if (locations.includes(location.pathname)) {
			setRouteActive(location.pathname);
		}
	}, [location]);

	useEffect(() => {
		let sessionUser = sessionStorage.getItem("user");
		if (sessionUser) {
			let userJson = JSON.parse(sessionUser);
			setUser(userJson);
		}

		let sessionAplicativo = sessionStorage.getItem("aplicativo");
		if (sessionAplicativo) {
			let aplicativoInt = parseInt(sessionAplicativo);
			setAplicativo(aplicativoInt);
		}

		setLoading(false);
	}, []);

	async function handleLogin(email: string, password: string) {
		let response = await apiService.login(email, password);
		if (response.Result === 1 && response.Data) {
			sessionStorage.setItem("user", JSON.stringify(response.Data));
			setUser(response.Data);
			return true;
		}

		return response.Message;
	}

	function handleLogout() {
		setUser(undefined);
		sessionStorage.removeItem("user");
		queryClient.invalidateQueries();
		navigate("/");
		return;
	}

	async function handleAplicativo(id: number) {
		sessionStorage.setItem("aplicativo", id.toString());
		queryClient.invalidateQueries();
		setAplicativo(id);
	}

	const value = {
		user,
		aplicativo,
		routeActive,
		handleLogin,
		handleLogout,
		handleAplicativo,
	};

	if (loading) {
		return <></>;
	}

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
