import { useEffect } from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";

export default function Home() {
	const { user } = useAuth();
	const { clearToast } = useToast();

	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		clearToast();
		// eslint-disable-next-line
	}, []);

	if (user) {
		return <Navigate to="/dashboard" state={{ from: location }} replace />;
	}

	return (
		<Container fluid>
			<Row className="justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
				<Col lg={6} style={{ maxWidth: 500 }}>
					<Card className="p-4 shadow bg-white">
						<Card.Body>
							<Image fluid className="p-3 ps-5 pe-5" src={process.env.PUBLIC_URL + "/logo.png"} />

							<div className="mt-3 mb-1 text-center fs-5">Front-End Cardif Whitelabel</div>
							<div className="mb-5 fw-bold text-center">RFQ n° 112107</div>
							<div className="mb-5 text-center">Aplicação demonstrativa do fluxo de contração de seguro</div>

							<Button
								type="button"
								size="lg"
								className="w-100 text-white"
								onClick={() => {
									navigate("/seguros");
								}}
							>
								Acessar seguros
							</Button>

							<div className="d-flex align-items-center justify-content-center mt-5">
								<a href="https://apps4business.com.br/">
									<Image src={process.env.PUBLIC_URL + "/apps.webp"} />
								</a>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}
