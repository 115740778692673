import { QueryClientProvider, QueryClient } from "react-query";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useToast } from "./ToastContext";

const queryClient = new QueryClient();

export default function QueryClientContext({ children }: any) {
	const navigate = useNavigate();
	const { handleToast } = useToast();

	queryClient.setDefaultOptions({
		queries: {
			onError: (error: unknown) => {
				if (axios.isAxiosError(error)) {
					const axiosError = error as AxiosError<any>;
					if (axiosError.response) {
						const status = axiosError.response.status;
						if (status === 400) {
							handleToast(null, "Erro 400: Solicitação inválida.", 5000, "danger");
						} else if (status === 401) {
							handleToast(null, "Erro 401: Credenciais inválidas.", 5000, "danger");
							navigate("/");
						} else if (status === 404) {
							handleToast(null, "Erro 404: Recurso não encontrado.", 5000, "danger");
						} else {
							handleToast(null, `Erro ${status}: ${axiosError.response.data.message}`, 5000, "danger");
						}
					} else if (axiosError.request) {
						handleToast(null, "Nenhuma resposta recebida.", 5000, "danger");
					} else {
						handleToast(null, `Erro: ${axiosError.message}`, 5000, "danger");
					}
				} else {
					handleToast(null, `Erro desconhecido: ${error}`, 5000, "danger");
				}
			},
		},
	});

	return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}
