import axios from "axios";

import { LoginEntity, LoginPostResponseEntity } from "../entities/LoginEntity";
import MockService from "./MockService";

const Request = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL });

/* API */
export default class ApiService {
	protected request = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL });
	protected mock = new MockService();

	constructor() {
		let user = this.user();
		this.request.defaults.headers.common["Authorization"] = `Bearer ${user.hashLogin}`;
	}

	protected user() {
		return JSON.parse(sessionStorage.getItem("user") ?? "{}") as LoginEntity;
	}

	public async login(email: string, password: string): Promise<LoginPostResponseEntity> {
		let response;
		try {
			let req = await Request.post<LoginPostResponseEntity>("/gerenciador/api/v1/Login", {
				login: email,
				senha: password,
				appVersion: "0.0.1",
				deviceToken: "",
				deviceType: 0,
				osVersion: "",
			});
			response = req.data;
		} catch (e) {
			return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" };
		}
		return response;
	}
}
