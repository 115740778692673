import React from "react";
import ReactDOM from "react-dom/client";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { BrowserRouter } from "react-router-dom";

import QueryClientContext from "./context/QueryClientContext";
import ToastProvider from "./context/ToastContext";
import ptBR from "date-fns/locale/pt-BR";
import App from "./App";

registerLocale("ptBR", ptBR);
setDefaultLocale("ptBR");

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
	<React.StrictMode>
		<BrowserRouter basename={process.env.PUBLIC_URL}>
			<ToastProvider>
				<QueryClientContext>
					<App />
				</QueryClientContext>
			</ToastProvider>
		</BrowserRouter>
	</React.StrictMode>
);
