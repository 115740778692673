import { useNavigate } from "react-router-dom";
import { Button, Container, FloatingLabel, Form } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiLogin } from "@mdi/js";

export default function Login() {
	const navigate = useNavigate();

	return (
		<Container fluid className="d-flex flex-column bg-primary p-0 shadow" style={{ minHeight: "100dvh", maxWidth: 450 }}>
			<div className="bg-primary text-white p-3">
				<div className="fs-2 mb-5">Entrar</div>
				<div className="d-flex justify-content-center mb-3">
					<Icon path={mdiLogin} size={4} />
				</div>
				<div className="text-center fs-1 mb-2">Entrar</div>
				<div className="text-center mb-5">Acesse a sua conta.</div>
			</div>
			<div className="d-flex flex-column flex-fill py-4 rounded-top-3 bg-white px-3">
				<div className="mb-4">
					<div className="text-center mb-3">Preencha os campos abaixo</div>
					<FloatingLabel controlId="email" label="E-mail" className="mb-3">
						<Form.Control type="email" placeholder="name@example.com" />
					</FloatingLabel>
					<FloatingLabel controlId="password" label="Senha" className="mb-3">
						<Form.Control type="password" placeholder="12345" />
					</FloatingLabel>
				</div>

				<div className="d-flex flex-column px-3 gap-3">
					<Button
						className="text-white rounded-pill"
						size="lg"
						onClick={() => {
							navigate("/cadastroConfirmar");
						}}
					>
						Entrar
					</Button>
					<Button
						className="text-white rounded-pill"
						size="lg"
						onClick={() => {
							navigate("/cadastro");
						}}
					>
						Cadastrar
					</Button>
				</div>
			</div>
		</Container>
	);
}
