import { mdiAccountMultipleOutline, mdiCogOutline, mdiOfficeBuildingOutline, mdiViewDashboardOutline } from "@mdi/js";

export const screens = [
	{
		menu: null,
		name: "Dashboard",
		description: "",
		icon: mdiViewDashboardOutline,
		route: "/dashboard",
		showSidebar: true,
		showDashboard: false,
	},
	{
		menu: null,
		name: "Avaliações",
		description: "Lista de avaliações de edificações",
		icon: mdiOfficeBuildingOutline,
		route: "/avaliacoes",
		showSidebar: true,
		showDashboard: true,
	},
	{
		menu: null,
		name: "Configurações",
		description: "Lista de configurações",
		icon: mdiCogOutline,
		route: "/Configuracoes",
		showSidebar: true,
		showDashboard: true,
	},
	{
		menu: null,
		name: "Usuários",
		description: "Lista de usuários do sistema",
		icon: mdiAccountMultipleOutline,
		route: "/usuarios",
		showSidebar: true,
		showDashboard: true,
	},
];
