import { Route, Routes } from "react-router-dom";

import "./App.scss";

import AuthProvider from "./context/AuthContext";
import ScrollToTop from "./components/ScrollToTop";
// import RouteAuth from "./components/RouteAuth";

import Home from "./screens/Home";
import Login from "./screens/Login";
import Cadastro from "./screens/Cadastro";
import CadastroConfirmar from "./screens/CadastroConfirmar";
import Seguros from "./screens/Seguros";
import Seguro from "./screens/Seguro";
import SeguroDetalhes from "./screens/SeguroDetalhes";
import SeguroSolicitar from "./screens/SeguroSolicitar";
import SeguroPagamento from "./screens/SeguroPagamento";
import SeguroResumo from "./screens/SeguroResumo";
import SeguroSolicitado from "./screens/SeguroSolicitado";

function App() {
	return (
		<AuthProvider>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/login" element={<Login />} />
				<Route path="/cadastro" element={<Cadastro />} />
				<Route path="/cadastroConfirmar" element={<CadastroConfirmar />} />
				<Route path="/seguros" element={<Seguros />} />
				<Route path="/seguro/:id" element={<Seguro />} />
				<Route path="/seguroDetalhes/:id" element={<SeguroDetalhes />} />
				<Route path="/seguroSolicitar/:id" element={<SeguroSolicitar />} />
				<Route path="/seguroPagamento" element={<SeguroPagamento />} />
				<Route path="/seguroResumo" element={<SeguroResumo />} />
				<Route path="/seguroSolicitado" element={<SeguroSolicitado />} />
			</Routes>
		</AuthProvider>
	);
}

export default App;
