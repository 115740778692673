import { useNavigate, useParams } from "react-router-dom";
import { Button, Container, Form, Image, Tab, Tabs } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiClose, mdiGiftOutline, mdiHomeOutline, mdiWrenchOutline } from "@mdi/js";
import { useState } from "react";

export default function SeguroSolicitar() {
	const navigate = useNavigate();
	const { id } = useParams();

	const [banner, setBanner] = useState(true);

	return (
		<Container fluid className="d-flex flex-column bg-primary p-0 shadow position-relative" style={{ minHeight: "100dvh", maxWidth: 450 }}>
			<div className="bg-primary text-white p-3">
				<div className="fs-2 mb-5">Seguro {id === "1" ? "Residencial" : "Saúde"}</div>
				<div className="fs-2 mb-3 lh-1 text-center">
					{id === "1" ? "Seguro Casa Protegida" : "Seguro Você Protegido"}
					<br />
					R$ 228,00 (19,00/mês)
				</div>
				<div className="mb-3 text-center">
					Sem tarifas adicionais!
					<br />
					Pagamento feito por cartão de crédito.
				</div>
			</div>
			<div className="d-flex flex-column flex-fill py-4 rounded-top-3 bg-white px-3">
				<Tabs defaultActiveKey="Coberturas" className="mb-3 border-0" fill>
					<Tab eventKey="Coberturas" title="Coberturas">
						<div className="d-flex align-items-center gap-2 fs-5 fw-medium my-3">
							<Icon path={mdiHomeOutline} size={1} className="text-primary" /> Coberturas
						</div>
						<div className="fw-light text-muted">Incêndio, Queda de Raio e Explosão</div>
						<div className="mb-3">Indenização até: R$ 60.000,00</div>
						<div className="fw-light text-muted">Vendaval, Furacão, Ciclone, Tornado e Granizo</div>
						<div className="mb-3">Indenização até: R$ 3.500,00</div>
						<div className="fw-light text-muted">Perda ou Pagamento de Aluguel</div>
						<div className="mb-3">Indenização até: R$ 6.000,00</div>
						<div className="fw-light text-muted">Danos elétricos</div>
						<div className="mb-3">Indenização até: 500,00</div>
						<div className="fw-light text-muted">Roubo ou Furto Qualificado de Bens</div>
						<div className="mb-3">Limite de Indenização: R$ 3.500,00</div>
						<div className="fw-light text-muted">Carência</div>
						<div className="mb-3">Não há</div>
						<div className="fw-light text-muted">Franquia</div>
						<div className="mb-3">Não há</div>
					</Tab>
					<Tab eventKey="Assistências" title="Assistências">
						<div className="d-flex align-items-center gap-2 fs-5 fw-medium my-3">
							<Icon path={mdiWrenchOutline} size={1} className="text-primary" /> Assistências
						</div>
						<div className="fw-light text-muted">Residencial</div>
						<div className="mb-3">
							<ul>
								<li>ChaveiroM</li>
								<li>Mão de Obra HidráulicaM</li>
								<li>Mão de Obra ElétricaM</li>
								<li>Vidraceiro</li>
								<li>Persianas, Varal de Teto e Kit Banheiro</li>
								<li>Rejuntamento de Box, Louças, Pias e Tanques</li>
								<li>Instalação de Filtro de Água</li>
							</ul>
						</div>
						<div className="fw-light text-muted">Help Desk</div>
						<div className="mb-3">
							<ul>
								<li>Suportes Celulares e Smartphones</li>
								<li>Suporte Tablets</li>
								<li>Suporte GPS</li>
								<li>Suporte Computadores</li>
								<li>Suporte TVs, Home Theater, Video, Games, Blu-ray e DVDs</li>
							</ul>
						</div>
						<div className="fw-light text-muted">Suporte Saúde</div>
						<div className="mb-3">
							<ul>
								<li>Caminhada e/ou Corrida</li>
								<li>Condicionamento físico</li>
								<li>Educação alimentar</li>
							</ul>
						</div>
					</Tab>
					<Tab eventKey="Sorteio" title="Sorteio">
						<div className="d-flex align-items-center gap-2 fs-5 fw-medium my-3">
							<Icon path={mdiGiftOutline} size={1} className="text-primary" /> Sorteio
						</div>
						<div className="mb-3">Você concorre a sorteios de R$ 5.062,50 por 12 meses. Já pensou essa grana no seu bolso?</div>
					</Tab>
				</Tabs>

				<div className="mb-3">
					<div className="d-flex align-items-center gap-2 fs-5 fw-medium my-3">Condições</div>
					<div style={{ textAlign: "justify" }}>
						Crientamos a leitura das Condições Gerais do seguro disponivel no link acima. Seguro garantido pela Cardit do Brasil Seguros e Garantias S.A. CNPJ 08.279.191/0001- 84. Processo
						Susep 15414.901351/2016-11, Seguradora com endereço na Av. Pres. Juscelino Kubitschek: 1.909 - Torre Sul - Vila Olimpia, São Paulo - SP. 04543-011. Representante de Seguro: Hub
						Pagamentos S.A, CNPJ: 47.960.950/0001-21 Remuneração 43,33%%. Corretora: Viotto Corretora de Seguros e Previdência LTDA. CNPJ: 56.170.061/0001-51, Registro Susep N° 202089682
						Remuneração 0,03%. Serviços de Assistencia Residencial prestados por CDF - Central de Funcionamento Tecnologia e Participações, CNPJ 08.769.874-0001/10. (1) A utiização dos
						serviços de assistência está sujeita ás condições e aos limites previstos nas Condições Gerais disponibilizadas no site https://www.luizaseg.com.br/condicoes-gerais-de-
						assistencias.php. Capitalzação: Icatu Capitalização S.A CNPJ| 74.267.170/0001-73 Processo SUSEP 15414.900393/2019-87. Prêmio ja indicados mo valor liquido de R$ 5.062,50.
						Consulte o regulamento da promoção no Site regulamentos. bnpparibascardif.com.br. Modalidade Crientamos a leitura das Condições Gerais do seguro disponivel no link acima.
						Seguro garantido pela Cardit do Brasil Seguros e Garantias S.A. CNPJ 08.279.191/0001- 84. Processo Susep 15414.901351/2016-11, Seguradora com endereço na Av. Pres. Juscelino
						Kubitschek: 1.909 - Torre Sul - Vila Olimpia, São Paulo - SP. 04543-011. Representante de Seguro: Hub Pagamentos S.A, CNPJ: 47.960.950/0001-21 Remuneração 43,33%%. Corretora:
						Viotto Corretora de Seguros e Previdência LTDA. CNPJ: 56.170.061/0001-51, Registro Susep N° 202089682 Remuneração 0,03%. Serviços de Assistencia Residencial prestados por CDF -
						Central de Funcionamento Tecnologia e Participações, CNPJ 08.769.874-0001/10. (1) A utiização dos serviços de assistência está sujeita ás condições e aos limites previstos nas
						Condições Gerais disponibilizadas no site https://www.luizaseg.com.br/condicoes-gerais-de- assistencias.php. Capitalzação: Icatu Capitalização S.A CNPJ| 74.267.170/0001-73
						Processo SUSEP 15414.900393/2019-87. Prêmio ja indicados mo valor liquido de R$ 5.062,50. Consulte o regulamento da promoção no Site regulamentos. bnpparibascardif.com.br.
						Modalidade
					</div>
				</div>

				<div style={{ height: 150 }} />
				<div className="d-flex flex-column fixed-bottom align-items-center">
					<div className="p-3 w-100" style={{ maxWidth: 450 }}>
						<div className="d-flex flex-column bg-white p-3 rounded-3 shadow border">
							<Form.Check type={"checkbox"} id="termos" label={`Eu li e aceito os Termos do Seguro`} className="d-flex justify-content-center gap-2 my-2" />
							<Button
								className="text-white rounded-pill"
								size="lg"
								onClick={() => {
									navigate("/login");
								}}
							>
								Solicitar
							</Button>
						</div>
					</div>
				</div>
			</div>

			{banner && (
				<div className="d-flex flex-column position-fixed align-items-center justify-content-end" style={{ zIndex: 1090, inset: 0, background: "rgba(0,0,0,0.5)" }}>
					<div className="d-flex flex-column bg-white rounded-top-3 shadow w-100 overflow-hidden" style={{ maxWidth: 450 }}>
						<div className="d-flex align-items-center bg-primary text-white px-3 fs-5 fw-medium position-relative">
							<div className="flex-fill">
								Concorra a<br />
								R$ 5.062,50 ao
								<br />
								contratar seguro
							</div>
							<Image src={process.env.PUBLIC_URL + "/banner.png"} style={{ width: 200, height: 150, objectFit: "contain" }} />
							<Button
								variant="light"
								className="rounded-circle p-2 position-absolute"
								onClick={() => {
									setBanner(false);
								}}
								style={{ right: 10, top: 10 }}
							>
								<Icon path={mdiClose} size={1} />
							</Button>
						</div>
						<div className="mb-3 m-3">
							Você concorre a sorteios de <b>R$ 5.062,50</b> por 12 meses. Já pensou essa grana no seu bolso?
						</div>
						<Button
							className="text-white rounded-pill m-3"
							size="lg"
							onClick={() => {
								setBanner(false);
							}}
						>
							Ok, entendi
						</Button>
					</div>
				</div>
			)}
		</Container>
	);
}
