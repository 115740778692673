import { useNavigate } from "react-router-dom";
import { Button, Container, FloatingLabel, Form } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiAccountCreditCardOutline, mdiCreditCardOutline, mdiDrawPen } from "@mdi/js";
import moment from "moment";

export default function SeguroPagamento() {
	const navigate = useNavigate();

	return (
		<Container fluid className="d-flex flex-column bg-primary p-0 shadow" style={{ minHeight: "100dvh", maxWidth: 450 }}>
			<div className="bg-primary text-white p-3">
				<div className="fs-2 mb-4">Pagamento</div>
				<div className="fs-2 mb-3 lh-1 text-center">
					Seguro Casa Protegida
					<br />
					R$ 228,00 (19,00/mês)
				</div>
				<div className="mb-3 text-center">
					Sem tarifas adicionais!
					<br />
					Pagamento feito por cartão de crédito.
				</div>
			</div>
			<div className="d-flex flex-column flex-fill py-4 rounded-top-3 bg-white px-3">
				<div className="mb-4">
					<div className="d-flex align-items-center gap-2 fs-5 fw-medium mb-3">
						<Icon path={mdiDrawPen} size={1} className="text-primary" /> Contração
					</div>
					<div>
						<div>
							<span className="fw-light text-muted">Frequência: </span>
							<span className="mb-3">Mensal</span>
						</div>
						<div>
							<span className="fw-light text-muted">Renovação automática: </span>
							<span className="mb-3">Não</span>
						</div>
						<div>
							<span className="fw-light text-muted">Início vigência: </span>
							<span className="mb-3">{moment().format("DD/MM/YYYY")}</span>
						</div>
						<div>
							<span className="fw-light text-muted">Vencimento: </span>
							<span className="mb-3">{moment().add(5, "days").format("DD/MM/YYYY")}</span>
						</div>
						<div>
							<span className="fw-light text-muted">Parcela: </span>
							<span className="mb-3">1</span>
						</div>
						<div>
							<span className="fw-light text-muted">Total de parcelas: </span>
							<span className="mb-3">12</span>
						</div>
						<div>
							<span className="fw-light text-muted">Total: </span>
							<span className="mb-3">R$ 19,00</span>
						</div>
					</div>

					<div className="d-flex align-items-center gap-2 fs-5 fw-medium my-3">
						<Icon path={mdiCreditCardOutline} size={1} className="text-primary" /> Pagamento
					</div>
					<FloatingLabel controlId="numero" label="Número do cartão" className="mb-3">
						<Form.Control type="text" placeholder="Informe o número do cartão" />
					</FloatingLabel>
					<FloatingLabel controlId="validade" label="Data de validade" className="mb-3">
						<Form.Control type="text" placeholder="MM/AA" />
					</FloatingLabel>
					<FloatingLabel controlId="nome" label="Nome no cartão de crédito" className="mb-3">
						<Form.Control type="text" placeholder="MM/AA" />
					</FloatingLabel>
					<FloatingLabel controlId="cvv" label="Código de segurança (CVV)" className="mb-3">
						<Form.Control type="text" placeholder="000-000" />
					</FloatingLabel>

					<div className="d-flex align-items-center gap-2 fs-5 fw-medium my-3">
						<Icon path={mdiAccountCreditCardOutline} size={1} className="text-primary" /> Titular do Cartão
					</div>
					<FloatingLabel controlId="cpf" label="CPF do titular do cartão" className="mb-3">
						<Form.Control type="text" placeholder="Informe o número do cartão" />
					</FloatingLabel>
					<FloatingLabel controlId="celular" label="Celular do titular do cartão" className="mb-3">
						<Form.Control type="text" placeholder="(99) 99999-9999" />
					</FloatingLabel>
					<FloatingLabel controlId="data" label="Data de nascimento do titular do cartão" className="mb-3">
						<Form.Control type="text" placeholder="DD/MM/AA" />
					</FloatingLabel>
				</div>

				<div className="d-flex flex-column px-3 gap-3">
					<Button
						className="text-white rounded-pill"
						size="lg"
						onClick={() => {
							navigate("/seguroResumo");
						}}
					>
						Confirmar pagamento
					</Button>
				</div>
			</div>
		</Container>
	);
}
